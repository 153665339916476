import {DynamicPaymentMethodsShape, DynamicPaymentMethodsTheme} from '@wix/wixstores-client-storefront-sdk';
import {ICartStyleSettings} from '../../types/app.types';
import {StyleParam} from '../../common/constants';
import {CheckoutButtonsPosition} from '@wix/wixstores-client-core';

export const defaultStyles: ICartStyleSettings = {
  booleans: {
    [StyleParam.ShowContinueShopping]: false,
    [StyleParam.ShowCoupon]: true,
    [StyleParam.ShowExpressCheckout]: true,
    [StyleParam.ShowBuyerNote]: true,
    [StyleParam.ShowTax]: false,
    [StyleParam.ShowShipping]: true,
    [StyleParam.Responsive]: false,
    [StyleParam.ShowCheckoutButton]: true,
    [StyleParam.ShowGoToCartButton]: true,
  },
  fonts: {
    [StyleParam.CornerRadius]: undefined,
    [StyleParam.SelectedSkin]: undefined,
  },
  numbers: {
    [StyleParam.dynamicPaymentMethodsShape]: DynamicPaymentMethodsShape.rect,
    [StyleParam.dynamicPaymentMethodsTheme]: DynamicPaymentMethodsTheme.dark,
    [StyleParam.CheckoutButtonsPosition]: Number(CheckoutButtonsPosition.ABOVE_AND_BELOW_CART),
  },
};
