import {FedopsInteractions as _1} from '@wix/wixstores-client-storefront-sdk';

export const FedopsInteractions = _1;

export const CART_COMMANDS_URL =
  '/_api/wix-ecommerce-renderer-web/store-front/cart/{commandName}?returnCartSummary=false';

export const EMPTY_CART_ID = '00000000-000000-000000-000000000000';

export enum CreateCheckoutExceptions {
  siteMustAcceptPayments = 'SITE_MUST_ACCEPT_PAYMENTS_TO_CREATE_CHECKOUT',
  unrecognizedError = 'UNRECOGNIZED_ERROR',
}

export const SEPERATOR = ' - ';

export enum StyleParam {
  ShowContinueShopping = 'cart_showContinueShopping',
  CornerRadius = 'cartButton_cornersRadius',
  dynamicPaymentMethodsTheme = 'cartButton_dynamicPaymentMethodsTheme',
  dynamicPaymentMethodsShape = 'cartButton_dynamicPaymentMethodsShape',
  SelectedSkin = 'cartButton_selectedSkin',
  ShowCoupon = 'cart_showCoupon',
  ShowExpressCheckout = 'cart_showExpressCheckout',
  ShowBuyerNote = 'cart_showBuyerNote',
  ShowTax = 'cart_showTax',
  ShowShipping = 'cart_showShipping',
  Responsive = 'responsive',
  CheckoutButtonsPosition = 'mobile:cart_checkoutButtonsPosition',
  ShowCheckoutButton = 'CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY',
  ShowGoToCartButton = 'CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY',
}

export const BI_APP_NAME = 'Stores';

export const CART_ORIGIN = 'shopping cart';
export const SIDE_CART_ORIGIN = 'side cart';

export enum ContinueShoppingBiOrigin {
  shoppingCart = 'shopping cart',
  minimumOrder = 'minimum_order_cart',
}

export enum OriginTypes {
  Paypal = 'paypal',
  AddToCart = 'addToCart',
}

export const PAYPAL_METHOD_NAME = 'PayPal';

export enum PaymentMethodType {
  creditCard = 'CREDIT_CARD',
  eWallet = 'HOSTED',
  offline = 'OFFLINE',
}

export enum ShippingMethodType {
  NONE = 'none',
  PICKUP = 'store pickup',
  SHIPPING = 'shipping',
}

export enum SlotsMap {
  mainBottom = 'cart-page:main:after-1',
}
